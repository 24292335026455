import React, { useState } from 'react';
import '../pages/admin/admin.css';
import axios from 'axios';

const NotificationSender = () => {
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  // Send notifications using the new API endpoint
  const sendNotifications = async () => {
    if (!message) {
      alert('Please enter a message.');
      return;
    }

    setSending(true);

    try {
      // Send the request to the new Firebase Functions API
      await axios.post('https://us-central1-scenes-84b3f.cloudfunctions.net/api/sendExpoNotification', {
        message: message,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      alert('Notifications sent successfully!');
    } catch (error) {
      console.error('Error sending notifications:', error);
      alert('Error sending notifications. Please try again later.');
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="col py-3">
          <div className="notification-sender">
            <h2>BCG African Sunsets</h2>
            <h4 style={{paddingBottom:20}}>Blast Notification Tool</h4>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                className="form-control"
                rows="3"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter message here..."
              ></textarea>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={sendNotifications}
              disabled={sending}
            >
              {sending ? 'Sending...' : 'Send Notification'}
            </button>
            {sending && <p>Sending notifications in batches...</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSender;
